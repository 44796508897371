import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import Image from "./image"
import Image from "../components/image"


const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `black`,
      marginBottom: `1.45rem`,
      alignItems: 'center',
      borderBottom: '1px solid #EC80E1'
    }}
  >
    <Image />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `StagedAppNYC`,
}

export default Header
